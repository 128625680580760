import React from 'react';
import dayjs from 'dayjs';

import { config as appConfig } from 'app/config';

import styles from './footer.module.scss';

export const Footer: React.FC = () => {
  const year = React.useMemo(() => dayjs().year(), []);

  return (
    <footer className={styles.footer}>
      <div>
        &copy; {year} {appConfig.name}
      </div>
      <div className={styles.developer}>
        <a
          href="https://www.avakomp.lt/kontaktai/"
          target="_blank"
          rel="noreferrer"
        >
          Avakompas
        </a>{' '}
        | avapay
      </div>
    </footer>
  );
};
